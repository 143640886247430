.case-details-item {
  .case-header-addon {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    gap: 1rem;
  }

  .case-details-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .case-sub-section-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .text-normal {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .text-small {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .detail-field {
    .label {
      font-weight: 400;
      font-size: 14px;
      color: var(--p-gray-500);
    }
    .value,
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    label {
      margin-left: 0.5rem;
    }

    &.checkbox-field {
      display: flex;
      align-items: center;

      .p-checkbox {
        .p-checkbox-box {
          opacity: 1;
        }
      }
    }

    &.radiobutton-field {
      display: flex;
      align-items: center;

      label {
        margin-left: 0.375rem;
      }

      .p-radiobutton {
        .p-radiobutton-box {
          opacity: 1;
        }
      }
    }

    &.infectious {
      .p-checkbox {
        .p-checkbox-box {
          border-color: #d32f2f !important;

          .p-highlight {
            border-color: #d32f2f !important;
            background: #d32f2f !important;
          }
        }

        &.p-checkbox-checked {
          .p-checkbox-box {
            background: #d32f2f !important;
          }
        }
      }
    }

    .p-radiobutton.p-radiobutton-checked {
      .p-radiobutton-icon {
        background: var(--p-surface-0) !important;
      }

      .p-radiobutton-box {
        background-color: var(--p-primary-500) !important;
        border-color: var(--p-primary-500) !important;
      }
    }
  }

  .case-address {
    font-size: 14px;
    .institution {
      font-weight: 500;
    }
  }

  .timeframe {
    font-weight: 500;
    color: var(--p-yellow-500);
    font-size: 14px;
    line-height: 17px;
  }
}

.case-details-section-divider {
  border-bottom: 1px dashed var(--border-color);
  margin: 1rem 0;
}
