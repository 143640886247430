.p-treeselect-label-container {
  display: flex;
}

.p-treeselect-label {
  display: block !important;
}

.p-treeselect-chip-item {
  cursor: default;
  display: inline-flex;
  align-items: center;
}

.p-treeselect-label .p-chip {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px !important;
  border: none;
}

.p-tree-empty-message {
  padding-top: 6px;
}
