.p-galleria {
  .p-galleria-item-container {
    flex-direction: column;

    .p-galleria-item-nav {
      top: 40%;
      background-color: var(--p-gray-300);
      color: var(--p-gray-500);

      &:not(.p-disabled):hover {
        background-color: var(--p-gray-500);
        color: var(--p-gray-300);
      }
    }

    .p-galleria-item {
      height: 400px;
      img {
        width: 100%;
        display: block;
        height: 400px;
        object-fit: contain;
      }

      p-skeleton {
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: center;

        .p-skeleton {
          width: 10rem !important;
          height: 10rem !important;
        }
      }
    }

    .p-galleria-caption {
      position: static;
      background-color: transparent;
      padding: 0.5rem 0;

      p-galleriaitemslot {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 100%;

        textarea {
          resize: none;
          flex: 1 1 300px !important;
        }
      }
    }
  }

  .p-galleria-thumbnail-container {
    background-color: transparent;
    gap: 0.5rem;
    align-items: center;

    .p-galleria-thumbnail-prev,
    .p-galleria-thumbnail-next {
      background-color: var(--p-gray-200);
      color: var(--p-gray-500);
      width: 70px;
      height: 70px;
      margin: 0;
      border-radius: 6px;
    }

    .p-galleria-thumbnail-items-container {
      display: flex;
    }

    .p-galleria-thumbnail-items {
      gap: 0.5rem;
      flex-wrap: wrap;
      justify-content: center;

      .thumbnail-wrapper {
        width: 70px;
        height: 70px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
        }
      }

      .p-galleria-thumbnail-item {
        flex: none !important;
      }
    }
  }
}
