.p-tooltip {
  max-width: revert;
  .p-tooltip-text {
    font-size: 14px;
  }
}
.hover {
  .p-tooltip-text {
    white-space: nowrap;
  }
}
.popper-hover {
  width: fit-content;
  max-width: 350px;

  .p-tooltip-text {
    background: white;
    color: black;
    overflow: hidden;
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: white;
    }
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: white;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: white;
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: white;
    }
  }
}

.p-tooltip-text {
  width: fit-content;
}
