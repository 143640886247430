.p-tabs {
  .p-tablist-tab-list {
    border-bottom-width: 2px;
    // border-bottom-color: transparent;

    position: relative;
    z-index: 1;

    li {
      .p-tab {
        // border-bottom-color: transparent;
        font-weight: normal;
        padding-left: 2rem;
        padding-right: 2rem;

        &:not(.p-disabled):focus {
          box-shadow: none;
        }
      }

      &.p-highlight {
        .p-tab {
          border-color: var(--p-primary-500);
          color: var(--p-primary-500);
        }
      }
    }
  }

  .p-tabpanels {
    background-color: transparent;
  }
}

.bottom-sidebar,
.p-drawer-bottom {
  height: 620px !important;

  .p-drawer-header {
    display: none !important;
  }

  .p-drawer-content {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    height: 100% !important;

    .content {
      flex: 1;
      display: flex;

      .left-column {
        width: 520px;
      }

      .right-column {
        flex: 1;
      }
    }
  }

  .sub-bar .right-column {
    .p-button {
      margin-left: 1rem;
    }
  }

  mgl-map {
    width: 100%;
    height: 100%;
  }
}

.cdk-overlay-pane {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow:
    0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  .flipped-position {
    transform: translateX(calc((100vw - 200px) / 8 - 10px)) !important;
  }
}

.rounding-bordered {
  border: 1px solid #dee2e6;
  background: #f8f9fa;
  color: #495057;
  border-radius: 3px;
  overflow: hidden;
}
