// .p-toast {
//   .p-toast-message {
//     box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
//     border-radius: 6px;

//     .p-toast-message-content {
//       padding: 0;

//       .p-toast-detail {
//         word-break: break-word;
//       }

//       .toast-wrapper {
//         display: flex;
//         flex: 1;

//         .icon-wrapper {
//           flex: 0 0 56px;
//           color: #fff;
//           display: flex;
//           align-items: center;
//           justify-content: center;

//           .pi {
//             font-size: 30px;
//           }
//         }

//         .content-wrapper {
//           flex: 1;
//           padding: 16px;

//           .toast-title {
//             font-weight: 700;
//             font-size: 1rem;
//             margin-bottom: 8px;
//           }

//           .toast-message-details {
//             color: #495057;
//             font-size: 14px;
//             margin-bottom: 8px;

//             .link {
//               font-weight: 700;

//               i.pi {
//                 margin-left: 3px;
//                 font-size: 13px;
//                 margin-right: 6px;
//               }
//             }
//           }
//         }
//       }

//       .p-toast-icon-close {
//         width: 3rem;
//         height: 3rem;
//         color: #c8ccd8;
//       }
//     }

//     &.p-toast-message-info,
//     &.p-toast-message-success,
//     &.p-toast-message-warn,
//     &.p-toast-message-error {
//       background-color: #fff;
//       border: none;
//     }

//     &.p-toast-message-info {
//       color: var(--p-primary-500);
//       .toast-wrapper {
//         .icon-wrapper {
//           background-color: var(--p-primary-500);
//         }
//       }
//     }

//     &.p-toast-message-success {
//       color: #8cc736;

//       .toast-wrapper {
//         .icon-wrapper {
//           background-color: #8cc736;
//         }
//       }
//     }

//     &.p-toast-message-warn {
//       color: #f59e0b;

//       .toast-wrapper {
//         .icon-wrapper {
//           background-color: #f59e0b;
//         }
//       }
//     }

//     &.p-toast-message-error {
//       color: #ef4444;

//       .toast-wrapper {
//         .icon-wrapper {
//           background-color: #ef4444;
//         }
//       }
//     }
//   }
// }

.p-toast-summary {
  display: contents;
}
