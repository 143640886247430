.bordered-button {
  border-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-style: solid;
}

.p-button {
  // background: var(--p-primary-500);
  // border: 1px solid rgba(0, 0, 0, 0.2);

  // &:enabled:hover {
  //   background: var(--color-primary-700);
  //   color: #ffffff;
  //   border-color: var(--color-primary-700);
  // }

  // &:focus {
  //   box-shadow: 0 0 0 0.2rem var(--color-primary-200);
  // }

  &.p-button-xs {
    padding: 0.25rem 0.5rem;
    font-size: 0.625rem;

    &.p-button-icon-only {
      width: 1.375rem;

      .pi {
        font-size: 0.625rem;
      }

      &.p-button-text {
        padding: 0.188rem 0.5rem;

        .pi {
          font-size: 0.875rem;
        }
      }
    }
  }

  &.p-button-outlined {
    color: var(--p-primary-500);

    &:enabled:hover {
      background: var(--color-primary-50);
      // background: rgba(33, 150, 243, 0.04);
      color: var(--p-primary-500);
    }
  }
}

.p-inputnumber-button-group {
  .p-button {
    border: 1px solid rgba(0, 0, 0, 0.2);

    &:enabled:hover {
      border-color: var(--color-primary-700);
    }
  }
}

.p-hidden {
  display: none !important;
}

.p-button-icon-left {
  // margin-right: 0.5rem;
}

.tours-actions {
  .p-button {
    height: 37px;
  }
}

.p-button-text.p-button-secondary:not(:disabled):hover {
  color: #495057 !important;
}

.p-button-label {
  flex: 1 1 auto;
}
