.p-datepicker-panel {
  border: 0 !important;
}

.p-datepicker-time-picker {
  padding: 0.5rem !important;
  .p-button-text {
    color: var(--p-text-color-secondary);
    &:not(:disabled) {
      &:hover {
        color: var(--p-text-color-secondary);
        background: #e9ecef;
      }
    }
  }
}

.p-datepicker-calendar {
  .p-datepicker-header {
    :not(.p-datepicker-title) {
      .p-button-text {
        color: var(--p-text-color-secondary);
        &:hover {
          color: var(--p-text-color-secondary);
          background: #e9ecef;
        }
      }
    }
  }
  .p-datepicker-title {
    button {
      font-size: 1rem;
    }
  }
}

.p-datepicker-clear-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  margin-top: -0.5rem;
  cursor: pointer;
}

.p-datepicker-input {
  padding-right: 2rem;
}

.p-datepicker:has(.p-datepicker-input-icon-container) {
  .p-datepicker-clear-icon {
    right: 2rem;
  }
}

.p-datepicker:has(.p-datepicker-input-icon-container, .p-datepicker-clear-icon)
  .p-datepicker-input {
  padding-right: 3rem !important;
}
