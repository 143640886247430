.p-multiselect-label:has(.p-chip) {
  padding: calc(var(--p-multiselect-padding-y) / 2) var(--p-multiselect-padding-x) !important;
}

.p-multiselect-label,
.p-autocomplete {
  .p-chip {
    border: none;
    background-color: #dee2e6;
    padding: 0.25rem 0.5rem;
  }
}

.p-multiselect-label {
  gap: 0.5rem !important;
}

.p-multiselect {
  width: 100%;
}
