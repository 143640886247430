// BLUE ARROW DROPDOWNS
p-multiselect.blue {
  .p-multiselect {
    width: 100%;
    border: none;

    .p-placeholder {
      font-size: 0.875rem;
      line-height: 26px;
    }

    &-label-container {
      padding: 0.25rem 0.5rem;
      border: 1px solid #ced4da;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &-label {
      padding: 0;
    }

    &-trigger {
      background: var(--p-primary-500);
      border-color: var(--p-primary-500);
      color: #ffffff;
    }

    &-token {
      color: var(--blue-700);
    }
  }
}
p-select.blue {
  &.p-select {
    width: 100%;
    border: none;

    .p-select-placeholder {
      font-size: 0.875rem;
      line-height: normal;
    }

    .p-select-label {
      padding: 0.5rem 0.5rem;
      border: 1px solid #ced4da;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-select-dropdown {
      background: var(--p-primary-500);
      border-color: var(--p-primary-500);
      color: #ffffff;
    }

    .p-select-token {
      color: var(--blue-700);
    }

    .p-select-clear-icon {
      right: 2.75rem;
    }
  }
}

.p-select-list {
  font-size: 0.875rem;
}

.p-select-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.p-select-empty-message {
  line-height: 0;
}
